import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth } from "../api/auth"; // Assuming auth context is being used

// Create Context
const SubjectsContext = createContext();

// Custom hook to use the SubjectsContext
export const useSubjects = () => {
  return useContext(SubjectsContext);
};

// SubjectsProvider Component
export const SubjectsProvider = ({ children }) => {
  const { user, API } = useAuth(); // Assuming user and API are from auth context
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) return;

    const fetchSubjects = async () => {
      const userData = {
        department: user?.department?.deptId,
        semester: user?.semester?.semName,
        scheme: user?.scheme?.scheme,
      };

      try {
        setIsLoading(true);
        const response = await fetch(`${API}/api/getSub`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch subjects");
        }

        const data = await response.json();
        const sortedSubjects = data.subjects.sort((a, b) => {
          const numA = parseInt(a.subCode.match(/\d+/)[0], 10);
          const numB = parseInt(b.subCode.match(/\d+/)[0], 10);
          return numA - numB;
        });

        setSubjects(sortedSubjects);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching subjects:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubjects();
  }, [user, API]);

  return (
    <SubjectsContext.Provider value={{ subjects, isLoading, error }}>
      {children}
    </SubjectsContext.Provider>
  );
};
