import React, { useState } from "react";
import "./nDss.css";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo192.png";
import SignInModal from "../../components/SignInModal/SignInModal";
import { useAuth } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import { TbCode } from "react-icons/tb";
import { LuBrainCircuit } from "react-icons/lu";
import { MdElectricBolt } from "react-icons/md";
import { FaGears } from "react-icons/fa6";
import { PiBuildingOfficeBold } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";

const NDss = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  if (isLoggedIn) {
    return navigate("/Welcome");
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };

  return (
    <div className="n-dss-page">
      <section className="n-hero-section">
        <nav className="n-navbar">
          <span className="n-logo">
            <Link to="/">
              <img
                src={Logo}
                alt="Znotes-logo"
                style={{ width: "50px", height: "auto", borderRadius: "50%" }}
              />
            </Link>
          </span>

          <div className="n-navbar-links">
            <div className="n-nav-links">
              {/* Streams Dropdown */}
            <span
              onClick={toggleDropdown}
              className={`n-streams-link ${isDropdownOpen ? "open" : ""}`}
            >
              <Link to="/streams">Streams</Link>
              <IoIosArrowDown
                style={{ marginLeft: "2px", fontSize: "1.2rem" }}
              />
            </span>

            {isDropdownOpen && (
              <div className="n-dropdown-menu">
                <Link to="/CSE" className="n-dropdown-item">
                  CSE <TbCode />
                </Link>
                <Link to="/ECE" className="n-dropdown-item">
                  ECE <LuBrainCircuit />
                </Link>
                <Link to="/EEE" className="n-dropdown-item">
                  EEE <MdElectricBolt />
                </Link>
                <Link to="/Civil" className="n-dropdown-item">
                  Civil <PiBuildingOfficeBold />
                </Link>
                <Link to="/Mechanical" className="n-dropdown-item">
                  Mech <FaGears />
                </Link>
              </div>
            )}
              <Link to="/about">About</Link>
              <Link to="/contact">Contact</Link>
            </div>{" "}
            <div className="n-auth-links">
              <Link
                to="/Subscriptionplans"
                className="n-custom-button bordered"
              >
                {" "}
                Sign Up
              </Link>{" "}
              <span style={{ fontSize: "2rem", color: "#8a6d4c" }}>/</span>
              {/* <Link to="/" className="n-custom-button filled">
                Sign In
              </Link> */}
              <Link
                to="#"
                onClick={openModal}
                className="n-custom-button filled"
              >
                Sign In
              </Link>
            </div>
          </div>
        </nav>
        <div className="n-hero-content-h1">
          <h1>
            Welcome to<p> Znotes.in</p>
          </h1>
        </div>

        <div className="n-hero-content-p">
          <p>
            Access comprehensive and well-organized Study Material tailored for
            your academic success.
          </p>
        </div>
      </section>

      <section className="n-features">
        <h2>What to See Here?</h2>
        <div className="n-features-container">
          {[
            {
              title: "Comprehensive Notes",
              description: "Thoroughly crafted notes for various subjects.",
              icon: "📝",
            },
            {
              title: "Regular Updates",
              description: "Stay current with new and updated content.",
              icon: "🔄",
            },
            {
              title: "External References",
              description:
                "Get external references for YouTube Lectures, Documentation, etc.",
              icon: "🔗",
            },
          ].map((feature, index) => (
            <div className="n-features-card" key={index}>
              <div className="n-feature-icon">{feature.icon}</div>
              <h2>{feature.title}</h2>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="n-features">
        <h2>Departments & Streams</h2>
        <div className="n-features-container">
          {[
            {
              title: "CSE",
              description: "Thoroughly crafted notes for various subjects.",
              icon: <TbCode />,
              link: "/CSE",
            },
            {
              title: "ECE",
              description: "Stay current with new and updated content.",
              icon: <LuBrainCircuit />,
              link: "/ECE",
            },
            {
              title: "EEE",
              description:
                "Get external references for YouTube Lectures, Documentation, etc.",
              icon: <MdElectricBolt />,
              link: "/EEE",
            },
            {
              title: "Civil",
              description:
                "Get external references for YouTube Lectures, Documentation, etc.",
              icon: <PiBuildingOfficeBold />,
              link: "/Civil",
            },
            {
              title: "Mechanical",
              description:
                "Get external references for YouTube Lectures, Documentation, etc.",
              icon: <FaGears />,
              link: "/Mechanical",
            },
          ].map((feature, index) => (
            <Link to={feature.link} key={index} className="n-features-card">
              {" "}
              {/* Wrap entire card with Link */}
              <div className="n-feature-icon">{feature.icon}</div>
              <h2>{feature.title}</h2>
              <p>{feature.description}</p>
            </Link>
          ))}
        </div>
      </section>

      <section className="n-about-section">
        <h2>About Us</h2>
        <p>Learn more about our mission and team.</p>
        <div className="n-about-content">
          <div className="n-about-card">
            <h3>Our Mission</h3>
            <p>
              To provide high-quality, accessible study materials for students
              everywhere.
            </p>
          </div>
          <div className="n-about-card">
            <h3>Meet the Team</h3>
            <p>Dedicated professionals committed to your academic success.</p>
          </div>
        </div>
      </section>

      <section className="n-contact-section">
        <h2>Contact Us</h2>
        <p>Get in touch with us for any queries or support.</p>
        <div className="n-contact-info">
          <div className="n-contact-method">
            <h3>Email</h3>
            <p>
              <a href="mailto:support@znotes.in">support@znotes.in</a>
            </p>
          </div>
          <div className="n-contact-method">
            <h3>Phone</h3>
            <p>
              <a href="tel:+1234567890">+123 456 7890</a>
            </p>
          </div>
        </div>
        <form className="n-contact-form">
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" required></textarea>
          <button type="submit" className="n-custom-button filled">
            Send Message
          </button>
        </form>
      </section>

      <SignInModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export { NDss };
