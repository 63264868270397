import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-container" style={styles.container}>
      <div className="terms-content" style={styles.content}>
        <h1 style={styles.title}>Terms and Conditions</h1>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Introduction</h2>
          <p style={styles.text}>
            Welcome to <strong>Znotes.in</strong>, a website designed and
            developed by Syed Zeeshan Patel. Here, we provide access to a
            variety of engineering notes in one place. These Terms and
            Conditions govern your use of the website and subscription services.
            By using our services, you agree to comply with these terms. If you
            do not agree, please do not use our services.
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>User Obligations</h2>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              You agree to use the website for educational and reference
              purposes only.
            </li>
            <li style={styles.listItem}>
              You will not misuse or distribute the content for illegal or
              commercial purposes.
            </li>
            <li style={styles.listItem}>
              You are responsible for keeping your subscription account and
              information secure.
            </li>
            <li style={styles.listItem}>
              Any unauthorized use of your account must be reported to us
              immediately.
            </li>
            <li style={styles.listItem}>
              You will not upload harmful or inappropriate content.
            </li>
          </ul>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Subscription and Access</h2>
          <p style={styles.text}>
            Znotes.in offers subscription-based access to educational content.
            By subscribing, you agree to our subscription terms, including
            renewal and cancellation policies.
          </p>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              The subscription fee grants access to the site for the specified
              duration. No additional services or courtesy should be expected
              beyond the provided access.
            </li>
            <li style={styles.listItem}>
              All payments made for the subscription are non-refundable.
            </li>
            <li style={styles.listItem}>
              We are only accountable for providing access to the site during
              your active subscription period.
            </li>
            <li style={styles.listItem}>
              Renewal prices may increase depending on the subscription plan you
              choose at the time of renewal.
            </li>
            <li style={styles.listItem}>
              You may change your subscription plan at the time of renewal.
            </li>
            <li style={styles.listItem}>
              Any delays in uploading notes or if certain notes are unavailable
              should not be grounds for demanding or disputing your
              subscription.
            </li>
            <li style={styles.listItem}>
            Any submission of incorrect details or information may lead to the cancellation of your subscription, and no refund will be provided.
            </li>
            <li style={styles.listItem}>
            Logging in to multiple devices may lead to the cancellation of your subscription, and no refund will be provided.
            </li>
            <li style={styles.listItem}>
              <b>
                By subscribing, you acknowledge that you have read and accepted
                all Terms and Conditions voluntarily, without any undue
                influence or pressure from others.
              </b>
            </li>
          </ul>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Privacy Policy</h2>
          <p style={styles.text}>
            We respect your privacy. Our Privacy Policy outlines how we collect,
            use, and protect your personal information. Please refer to our full
            Privacy Policy for more details.
          </p>
          <a href="/privacy-policy" style={styles.link}>
            Read our full Privacy Policy here.
          </a>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Intellectual Property</h2>
          <p style={styles.text}>
            The content on this website, including but not limited to notes,
            images, and logos, is owned by the respective content creators. The
            design, layout, and functionality of the website are owned by Syed
            Zeeshan Patel. You may not use the content for commercial purposes
            without appropriate permissions.
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Disclaimer</h2>
          <p style={styles.text}>
            <strong>Disclaimer:</strong> The content on this website is for
            educational and reference purposes only. The site owner assumes no
            responsibility for any misuse of the information provided. While we
            aim to provide accurate and reliable content, we do not guarantee
            its accuracy or completeness.
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Changes to Terms</h2>
          <p style={styles.text}>
            Znotes.in reserves the right to update or modify these Terms and
            Conditions at any time. Any changes will be reflected on this page,
            and it is your responsibility to review the Terms periodically.
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Governing Law</h2>
          <p style={styles.text}>
            These Terms are governed by the laws of India, specifically in the
            jurisdiction of Bidar, Karnataka. Any disputes will be resolved in
            the courts of Bidar, Karnataka.
          </p>
        </section>

        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>Contact Information</h2>
          <p style={styles.text}>
            If you have any questions about these Terms, please contact us at:{" "}
            <br />
            Email:{" "}
            <a href="mailto:support@znotes.in" style={styles.link}>
              support@znotes.in
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  },
  content: {
    lineHeight: "1.6",
    color: "#333",
  },
  title: {
    fontSize: "2.5em",
    marginBottom: "20px",
    textAlign: "center",
    color: "#2e3d49",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    fontSize: "1.75em",
    marginBottom: "10px",
    color: "#2e3d49",
  },
  text: {
    fontSize: "1.1em",
    color: "#555",
    marginBottom: "10px",
    lineHeight: "1.8",
  },
  list: {
    paddingLeft: "20px",
  },
  listItem: {
    fontSize: "1.1em",
    color: "#555",
    marginBottom: "8px",
    lineHeight: "1.8",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
    fontWeight: "bold",
  },
  linkHover: {
    textDecoration: "underline",
  },
};

export default TermsAndConditions;
