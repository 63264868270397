import React from 'react';
import { TbCode } from "react-icons/tb";
import { LuBrainCircuit } from "react-icons/lu";
import { MdElectricBolt } from "react-icons/md";
import { FaGears } from "react-icons/fa6";
import { PiBuildingOfficeBold } from "react-icons/pi";
import { Link } from "react-router-dom";



const Departments = () => {
  return (
    <section className="n-features">
            <h2>Departments & Streams</h2>
            <div className="n-features-container">
            {[
              {
                title: "CSE",
                description: "Thoroughly crafted notes for various subjects.",
                icon: <TbCode />,
                link: "/CSE",
              },
              {
                title: "ECE",
                description: "Stay current with new and updated content.",
                icon: <LuBrainCircuit />,
                link: "/ECE", 
              },
              {
                title: "EEE",
                description: "Get external references for YouTube Lectures, Documentation, etc.",
                icon: <MdElectricBolt />,
                link: "/EEE",
              },
              {
                title: "Civil",
                description: "Get external references for YouTube Lectures, Documentation, etc.",
                icon: <PiBuildingOfficeBold />,
                link: "/Civil", 
              },
              {
                title: "Mechanical",
                description: "Get external references for YouTube Lectures, Documentation, etc.",
                icon: <FaGears />,
                link: "/Mechanical",
                },
              ].map((feature, index) => (
                <Link to={feature.link} key={index} className="n-features-card"> {/* Wrap entire card with Link */}
                  <div className="n-feature-icon">{feature.icon}</div>
                  <h2>{feature.title}</h2>
                  <p>{feature.description}</p>
                </Link>
              ))}
            </div>
          </section>
  );
};

export default Departments;
