import React, { useState, useEffect } from "react";
import "./SignUp.css";
import { toast } from "react-toastify";
import { useAuth } from "../../api/auth";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";

const SignUp = () => {
  const { API } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  // Retrieve the selected plan from the navigation state
  const selectedPlanFromState = location.state?.selectedPlan || null;

  const [options, setOptions] = useState({
    dssData: {
      department: [],
      scheme: [],
      semester: [],
      batch: [],
      college: [],
    },
  });

  useEffect(() => {
    // Fetch departments, schemes, and semesters data
    const fetchData = async () => {
      try {
        const response = await fetch(`${API}/api/cdss`);
        const data = await response.json();
        setOptions(data);
        // console.log("college", options);
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [API]);

  const [formData, setFormData] = useState({
    "form-name": "sign-up",
    usn: "",
    name: "",
    gender: "",
    email: "",
    password: "",
    contact: "",
    dob: "",
    department: "",
    semester: "",
    scheme: "",
    batch: "",
    college: "",
    utr: "",
    plan: selectedPlanFromState,
    // studentExists: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedUsn = name === "usn" ? value.toUpperCase() : value;
    setFormData({ ...formData, [name]: updatedUsn });
  };

  useEffect(() => {
    // Calculate and set the initial value of scheme when batch changes
    if (formData.batch) {
      const calculatedScheme = calculateScheme(formData.batch);
      setFormData((prevData) => ({ ...prevData, scheme: calculatedScheme }));
    }
  }, [formData.batch]);

  const calculateScheme = (batch) => {
    // Define your logic to calculate scheme based on batch here
    switch (batch) {
      case "2021":
        return "2021";
      case "2022":
        return "2022";
      case "2023":
        return "2022";
      case "2024":
        return "2022";
      case "2025":
        return "2025";
      default:
        return "";
    }
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      Swal.fire({
        icon: "success",
        title: "🎉",
        text: "Your registration has been completed. You will receive a confirmation email shortly. Please wait until then.",
      });

      toast.success("Sign-up successful!");

      navigate("/")

      setFormData({
        usn: "",
        name: "",
        gender: "",
        email: "",
        password: "",
        contact: "",
        dob: "",
        department: "",
        semester: "",
        scheme: "",
        batch: "",
        college: "",
        utr: "",
      });
    } catch (error) {
      alert(error);
    }

    setIsLoading(false);
  };


// Validate DOB on change to ensure it's at least 15 years old
const validateDOB = (dob) => {
  const today = new Date();
  const minDOB = new Date();
  minDOB.setFullYear(today.getFullYear() - 15); // Prevent modifying 'today'
  const minDOBString = minDOB.toISOString().split("T")[0]; // YYYY-MM-DD format

  if (new Date(dob) > new Date(minDOBString)) {
    Swal.fire({
      icon: "error",
      title: "Invalid Date of Birth",
      text: "Please give the correct input",
      confirmButtonText: "OK",
    });
  }
};


  return (
    <div className="signup-model">
      <h2 className="signup-h2">Sign Up</h2>
      {/* <div className="signup-form"> */}
      <div className="form-container">
        <p style={{ margin: "12px", fontSize: "15px", color: "black" }}>
        Fill in the registration form carefully to gain access to ZNotes.in. <br/>Any incorrect information may lead to the cancellation of your<br/> subscription without a refund.
        </p>
        {isFetching ? (
          <Spinner />
        ) : (
          <>
            <form
              method="POST"
              name="sign-up"
              data-netlify="true"
              onSubmit={handleSubmit}
            >
              {/* <div className="signup-grid"> */}
              {/* <div className="signup-input-wrapper"> */}
              <div className="form-group">
                <div className="inputbox">
                  <input
                    type="text"
                    name="plan"
                    value={
                      formData.plan ? `${formData.plan} Plan ` : "Loading..."
                    }
                    readOnly
                    style={{ fontWeight: "bolder" }}
                  />
                  {/* <span>Plan</span> */}
                </div>

                <div className="inputbox">
                  <input
                    type="text"
                    name="utr"
                    required
                    value={formData.utr}
                    onChange={handleInputChange}
                  />

                  <span>UTR No.</span>
                </div>
              </div>
              <div className="form-group">
                <div className="inputbox">
                  <input
                    type="text"
                    name="name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <span>Name </span>
                </div>

                <div className="inputbox">
                  <input
                    type="text"
                    name="usn"
                    required
                    value={formData.usn}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                  <span>USN/Roll No.</span>
                </div>
              </div>
              <div>
                <div className="form-group">
                  <div className="inputbox">
                    <input
                      type="text"
                      name="contact"
                      required
                      value={formData.contact}
                      onChange={handleInputChange}
                    />
                    <span>Contact No.</span>
                  </div>

                  <div className="inputbox">
                    <input
                      type="email"
                      name="email"
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    <span>Email </span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="inputbox">
                    <select
                      name="gender"
                      required
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <option value=""></option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    <span>Gender</span>
                  </div>

                  <div className="inputbox">
                    <input
                      type="date"
                      name="dob"
                      required
                      value={formData.dob}
                      onChange={(e) => {
                        handleInputChange(e); 
                        validateDOB(e.target.value); // validate DOB immediately
                      }}// Prevents selecting a date before 15 years ago
                    />

                    <span
                      style={{
                        backgroundColor: "white",
                        margin: "2px",
                      }}
                    >
                      Date of Birth
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="inputbox">
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                    <span>Password </span>
                  </div>
                  <div className="inputbox">
                    <select
                      name="department"
                      value={formData.department}
                      onChange={handleInputChange}
                      required
                    >
                      <option value=""></option>
                      {options.dssData.department.map((department, index) => (
                        <option key={index} value={department.deptId}>
                          {department.deptName}
                        </option>
                      ))}
                    </select>
                    <span>Department</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="inputbox" style={{ width: "33%" }}>
                    <select
                      name="batch"
                      value={formData.batch}
                      onChange={handleInputChange}
                      required
                    >
                      <option value=""></option>

                      {options.dssData.batch.map((batch, index) => (
                        <option key={index} value={batch.batch}>
                          {batch.batch}
                        </option>
                      ))}
                    </select>
                    <span>Batch</span>
                  </div>
                  <div className="inputbox" style={{ width: "33%" }}>
                    <select
                      name="scheme"
                      value={formData.scheme}
                      onChange={handleInputChange}
                      required
                    >
                      <option value=""></option>

                      {options.dssData.scheme.map((scheme, index) => (
                        <option key={index} value={scheme.scheme}>
                          {scheme.scheme}
                        </option>
                      ))}
                    </select>
                    <span>Scheme</span>
                  </div>
                  <div className="inputbox" style={{ width: "33%" }}>
                    <select
                      name="semester"
                      value={formData.semester}
                      onChange={handleInputChange}
                      required
                    >
                      <option value=""></option>

                      {options.dssData.semester.map((semester, index) => (
                        <option key={index} value={semester.semNum}>
                          {semester.semName}
                        </option>
                      ))}
                    </select>
                    <span>Semester</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="inputbox" style={{ width: "100%" }}>
                    <select
                      name="college"
                      value={formData.college}
                      onChange={handleInputChange}
                      required
                    >
                      <option value=""></option>
                      {options.dssData.college.map((college, index) => (
                        <option key={index} value={college.clgCode}>
                          {college.clgName}
                        </option>
                      ))}
                    </select>
                    <span>Select College</span>
                  </div>
                </div>
              </div>
              <div className="signup-btn-container">
                <button
                  className="n-custom-button filled"
                  style={{ width: "100%" }}
                  // onClick={handleSignUp}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : "Sign Up"}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export { SignUp };
