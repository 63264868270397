import React, { useState } from "react";
import "./SubscriptionPage.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import qr from "../../Assets/qr.jpg";

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isTnCAccepted, setIsTnCAccepted] = useState(false);

  // Handle selecting a plan
  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan); // Update the selected plan
  };

  // Handle the "Proceed" action
  const handleProceed = () => {
    if (!isTnCAccepted) {
      // SweetAlert2: Display a warning if T&C are not accepted
      Swal.fire({
        icon: "warning",
        title: "Terms and Conditions",
        text: "Please accept the Terms and Conditions before proceeding.",
        confirmButtonText: "Okay",
      });
      return;
    }

    if (selectedPlan) {
      navigate("/SignUp", { state: { selectedPlan } }); // Passing state
    } else {
      // SweetAlert for plan selection warning
      Swal.fire({
        icon: "warning",
        title: "Select a Plan",
        text: "Please select a plan before proceeding.",
        confirmButtonText: "Okay",
      });
    }
  };

  // Handle checkbox change for accepting T&C
  const handleTnCChange = () => {
    setIsTnCAccepted(!isTnCAccepted); // Toggle T&C acceptance
  };

  return (
    <div className="subscription-page">
      <div className="container">
        <h1 className="title">Choose Your Plan</h1>
        <p className="description">
          Pick the best plan that suits your needs. Our plans are designed to
          give you the best value!
        </p>

        <div className="plans">
          {/* Basic Plan */}
          <div
            className={`plan-card ${
              selectedPlan === "Basic" ? "selected" : ""
            }`}
            onClick={() => handleSelectPlan("Basic")}
          >
            <div className="plan-header">
              <h3 className="plan-title">Basic</h3>
              <p className="plan-price">
                <span className="original-price">₹99/Sem</span> ₹49/Sem
              </p>
            </div>
            <ul className="plan-features">
              <li>Duration 1 sem only</li>
              <li>Full access</li>
              <li>Price may increase on renewal</li>
            </ul>
            <div className="plan-action">
              <button
                className="n-custom-button filled"
                onClick={() => handleSelectPlan("Basic")} // Ensure selection logic is here
              >
                Select Plan
              </button>
            </div>
          </div>

          {/* Premium Plan with "Recommended" label */}
          <div
            className={`plan-card premium ${
              selectedPlan === "Premium" ? "selected" : ""
            }`}
            onClick={() => handleSelectPlan("Premium")}
          >
            <div className="recommended-label">Recommended</div>
            <div className="plan-header">
              <h3 className="plan-title">Premium (47% off)</h3>
              <p className="plan-price">
                <span className="original-price">₹149/Year</span> ₹79/Year
              </p>
            </div>
            <ul className="plan-features">
              <li>for 2 sem / 1 year</li>
              <li>Full access</li>
              <li>No tension for a year</li>
            </ul>
            <div className="plan-action">
              <button
                className="n-custom-button filled"
                onClick={() => handleSelectPlan("Premium")} // Ensure selection logic is here
              >
                Select Plan
              </button>
            </div>
          </div>
        </div>

        {selectedPlan && (
          <div className="selected-plan-summary">
            <h2>
              You have selected the{" "}
              {selectedPlan === "Basic" ? "Basic Plan" : "Premium Plan"}
            </h2>
            <p>
              {selectedPlan === "Basic"
                ? "The Basic Plan is valid for one semester only. Later on renewal price may increase."
                : "The Premium Plan is valid for 2 semesters. Since this is discounted price, it will be ₹149/- on renewal "}
            </p>
            <p>
              {selectedPlan === "Basic"
                ? "Make a payment of ₹49/- on the below QR, keep the UTR no. handy and click PROCEED"
                : "Make a payment of ₹79/- on the below QR, keep the UTR no. handy and click PROCEED"}
            </p>

            <div className="qr" >
              <img src={qr} alt="Qr Code" style={{width:"100%", maxWidth:"400px"}}/>
            </div>

            {/* T&C Checkbox */}
            <div className="tnc-container">
              <input
                type="checkbox"
                id="acceptTnC"
                checked={isTnCAccepted}
                onChange={handleTnCChange}
              />
              <label htmlFor="acceptTnC">
                I accept the{" "}
                <a
                  href="/TermsAndConditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </label>
            </div>

            {/* Proceed Button */}
            <button className="n-custom-button filled" onClick={handleProceed}>
              Proceed
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPage;
