import React from 'react';
const AboutPage = () => {
  return (
    <section className="n-about-section">
    <h2>About Us</h2>
    <p>Learn more about our mission and team.</p>
    <div className="n-about-content">
      <div className="n-about-card">
        <h3>Our Mission</h3>
        <p>
          To provide high-quality, accessible study materials for students
          everywhere.
        </p>
      </div>
      <div className="n-about-card">
        <h3>Meet the Team</h3>
        <p>Dedicated professionals committed to your academic success.</p>
      </div>
    </div>
  </section>
  );
};

export default AboutPage;
