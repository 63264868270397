import React from 'react';

const ContactPage = () => {
  return (
    <section className="n-contact-section">
    <h2>Contact Us</h2>
    <p>Get in touch with us for any queries or support.</p>
    <div className="n-contact-info">
      <div className="n-contact-method">
        <h3>Email</h3>
        <p>
          <a href="mailto:support@znotes.in">support@znotes.in</a>
        </p>
      </div>
      <div className="n-contact-method">
        <h3>Phone</h3>
        <p>
          <a href="tel:+1234567890">+123 456 7890</a>
        </p>
      </div>
    </div>
    <form className="n-contact-form">
      <input type="text" placeholder="Your Name" required />
      <input type="email" placeholder="Your Email" required />
      <textarea placeholder="Your Message" required></textarea>
      <button type="submit" className="n-custom-button filled">
        Send Message
      </button>
    </form>
  </section>
  );
};


export default ContactPage;
