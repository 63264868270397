// *--------------------------
// * Logic for Student Login with unique device
// *--------------------------


import React, { useState, useEffect } from "react";
import "./SignInModal.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../api/auth";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import ResetPassword from "../../components/ResetPasswordPopup/ResetPassword";

const SignInModal = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const navigate = useNavigate();
  const { storeTokenInLS, API } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    usn: "",
    password: "",
  });

  // Fetch some initial data on mount if necessary
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API}/api/cdss`);
        const data = await response.json();
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [API]);

  // Early return if modal is not open
  if (!isOpen) return null;

  // Show the reset password modal
  const ShowResetPassword = () => {
    setShowResetPassword(true);
  };

  // Close the reset password popup
  const handleClosePopup = () => {
    setShowResetPassword(false);
  };

  // Handle input field changes (for USN and password)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedUsn = name === "usn" ? value.toUpperCase() : value;
    setFormData({ ...formData, [name]: updatedUsn });
  };

  // Collect device data (user-agent, device type) and handle login
  const getDeviceData = () => {
    return {
      userAgent: navigator.userAgent,
      deviceType: /Mobi|Android/i.test(navigator.userAgent) ? "mobile" : "desktop",
    };
  };

  // Handle login process
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const deviceData = getDeviceData(); // Collect the device data (userAgent, device type)

    try {
      const response = await fetch(`${API}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, deviceData }), // Send both form data and device data
      });

      if (response.ok) {
        const res_data = await response.json();
        storeTokenInLS(res_data.token); // Store token in local storage for future use
        toast.success("Login successful!"); // Show success toast
        navigate("/Welcome"); // Redirect to the welcome page after successful login
        setFormData({ usn: "", password: "" }); // Clear form data after successful login
      } else {
        toast.error("Invalid USN or Password"); // Show error if credentials are incorrect
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again."); // Show error for any other issue
    }

    setLoading(false); // Stop the loading spinner
    onClose(); // Close the modal after login attempt
  };

  return (
    <div className="n-modal-overlay">
      <div className="n-modal-content">
        <button className="n-close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Sign In</h2>

        {isLoading ? (
          <Spinner /> // Show a loading spinner if the data is still being fetched
        ) : (
          <form className="n-login-form" style={{ width: "100%" }} onSubmit={handleLogin}>
            <div className="n-login-form-ip">
              <input
                type="text"
                name="usn"
                value={formData.usn}
                onChange={handleInputChange}
                required
              />
              <span>USN</span>
            </div>

            <div className="n-login-form-ip">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <span>Password</span>
            </div>

            <span className="n-forget-password" onClick={ShowResetPassword}>
              Forget Password?
            </span>

            <button
              type="submit"
              className="n-custom-button filled"
              style={{ width: "90%", padding: "16px", width: "100%" }}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Sign In"} {/* Show a loading spinner when submitting */}
            </button>
          </form>
        )}

        <div className="n-or-divider-login">Or</div>

        <p className="n-l-link-text">
          Don't have an account?{" "}
          <Link to="/SignUp" className="n-sign-up-link">
            Sign Up
          </Link>
        </p>
      </div>
      {showResetPassword && <ResetPassword onClose={handleClosePopup} />} {/* Show reset password popup if needed */}
    </div>
  );
};

export default SignInModal;




























// *--------------------------
// * Logic for Student Login without unique device / pehle ka code
// *--------------------------


// // SignInModal.jsx

// import React, { useState, useEffect } from "react";
// import "./SignInModal.css";
// import { Link, useNavigate } from "react-router-dom";
// import { useAuth } from "../../api/auth";
// import { toast } from "react-toastify";
// import Spinner from "../Spinner";
// import ResetPassword from "../../components/ResetPasswordPopup/ResetPassword";

// const SignInModal = ({ isOpen, onClose }) => {
//   // Call hooks first, before early return
//   const [loading, setLoading] = useState(false);
//   const [showResetPassword, setShowResetPassword] = useState(false);
//   const navigate = useNavigate();
//   const { storeTokenInLS, API } = useAuth();
//   const [isLoading, setIsLoading] = useState(true);

//   // Fetch data on mount
//   useEffect(() => {
//     // Fetch departments, schemes, and semesters data
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${API}/api/cdss`);
//         const data = await response.json();

//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [API]);

//   const [formData, setFormData] = useState({
//     usn: "",
//     password: "",
//   });

//   // Early return if modal is not open
//   if (!isOpen) return null;

//   const ShowResetPassword = () => {
//     setShowResetPassword(true);
//   };

//   const handleClosePopup = () => {
//     setShowResetPassword(false);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     const updatedUsn = name === "usn" ? value.toUpperCase() : value;
//     setFormData({ ...formData, [name]: updatedUsn });
//   };

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       // const response = await fetch(`http://localhost:5000/api/login`, {
//       const response = await fetch(`${API}/api/login`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       // Handle response based on success or failure
//       // console.log("Login Form");

//       if (response.ok) {
//         const res_data = await response.json();

//         // console.log("Response from Server", res_data);
//         storeTokenInLS(res_data.token);

//         toast.success("Ha Hogaya login");

//         navigate("/Welcome");

//         setFormData({ usn: "", password: "" });

//         setLoading(false); // Ensure the loading state is cleared
//       } else {
//         toast.error("Nice try! But aap galat ho");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//     setLoading(false);
//     onClose();
//   };

//   return (
//     <div className="n-modal-overlay">
//       <div className="n-modal-content">
//         <button className="n-close-button" onClick={onClose}>
//           &times;
//         </button>
//         <h2>Sign In</h2>

//         {isLoading ? (
//           <Spinner />
//         ) : (
//           <>
//             <form className="n-login-form" style={{ width: "100%" }}>
//               <div className="n-login-form-ip">
//                 <input
//                   type="text"
//                   name="usn"
//                   value={formData.usn}
//                   onChange={handleInputChange}
//                   required
//                 />
//                 <span>USN</span>
//               </div>

//               <div className="n-login-form-ip">
//                 <input
//                   type="password"
//                   name="password"
//                   value={formData.password}
//                   onChange={handleInputChange}
//                   required
//                 />
//                 <span>Password</span>
//               </div>
//               <span className="n-forget-password" onClick={ShowResetPassword}>
//                 Forget Password ?
//               </span>
//               <button
//                 type="submit"
//                 className="n-custom-button filled"
//                 style={{ width: "90%", padding: "16px", width: "100%" }}
//                 onClick={handleLogin}
//                 disabled={loading}
//               >
//                 {loading ? <Spinner /> : "Sign In"}
//               </button>
//             </form>
//           </>
//         )}

//         <div className="n-or-divider-login">Or</div>
//         <p className="n-l-link-text">
//           Don't have an account?{" "}
//           <Link to="/SignUp" className="n-sign-up-link">
//             Sign Up
//           </Link>
//         </p>
//       </div>
//       {showResetPassword && <ResetPassword onClose={handleClosePopup} />}
//     </div>
//   );
// };

// export default SignInModal;
