// DepartmentPage.jsx

import React from 'react';
import { useParams } from 'react-router-dom';
import './DepartmentPage.css';  // Assuming you're adding custom styling
import Swal from 'sweetalert2';  // Import SweetAlert2

function DepartmentPage() {
  const { department } = useParams();  // Get department from the URL
  console.log("Department from URL:", department);  // Debugging: Log department parameter

  // Convert the department to lowercase to ensure case insensitivity
  const departmentData = getDepartmentData(department.toLowerCase());  // Convert to lowercase before passing

  console.log("Department Data:", departmentData);  // Debugging: Log the department data

  if (!departmentData) {
    return <h2>Department not found</h2>;  // Handle invalid department
  }

  const handleCardClick = (type, semester, subject) => {
    // Show a Swal prompt asking the user to log in
    Swal.fire({
      title: 'Please Login to Go Further',
      text: `You need to be logged in to access ${type} ${semester} ${subject ? '- ' + subject : ''}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Login',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect user to login page (you can adjust the path)
        window.location.href = '/'; // Replace '/login' with your actual login route
      }
    });
  };

  return (
    <div className="n-department-page">
      <section className="n-department-hero">
        <div className="n-hero-content">
          <h1>{departmentData.title}</h1>
          <p>{departmentData.tagline}</p>
        </div>
      </section>

      <section className="n-department-content">
        <h2>Semester Overview</h2>
        <div className="n-department-sems">
          {departmentData.sems.map((sem, index) => (
            <div
              className="n-department-sem"
              key={index}
              onClick={() => handleCardClick('Semester', sem.semester)} // Handle semester click
            >
              <h3>Semester {sem.semester}</h3>
              <ul>
                {sem.subjects.map((subject, idx) => (
                  <li
                    key={idx}
                    onClick={(e) => {
                      e.stopPropagation();  // Prevent triggering semester click
                      handleCardClick('Subject', sem.semester, subject);  // Handle subject click
                    }}
                  >
                    {subject}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

// Function to get department data dynamically
function getDepartmentData(department) {
  const departments = {
    cse: {
      title: "Computer Science & Engineering",
      tagline: "Innovating the Future of Technology",
      sems: [
        { semester: "1", subjects: ["Introduction to Programming", "Mathematics I", "Physics", "Communication Skills"] },
        { semester: "2", subjects: ["Data Structures", "Mathematics II", "Digital Logic", "Computer Networks"] },
        { semester: "3", subjects: ["Algorithms", "Operating Systems", "Database Management Systems", "Discrete Mathematics"] },
        { semester: "4", subjects: ["Software Engineering", "Computer Architecture", "Theory of Computation", "Design & Analysis of Algorithms"] },
        { semester: "5", subjects: ["Data Structures", "Mathematics II", "Digital Logic", "Computer Networks"] },
        { semester: "6", subjects: ["Algorithms", "Operating Systems", "Database Management Systems", "Discrete Mathematics"] },
        { semester: "7", subjects: ["Software Engineering", "Computer Architecture", "Theory of Computation", "Design & Analysis of Algorithms"] }
      ]
    },
    ece: {
      title: "Electronics & Communication Engineering",
      tagline: "Building the Future of Communication Systems",
      sems: [
        { semester: "1", subjects: ["Basic Electrical Engineering", "Mathematics I", "Physics", "Communication Systems"] },
        { semester: "2", subjects: ["Analog Electronics", "Mathematics II", "Signals & Systems", "Digital Circuits"] },
        { semester: "3", subjects: ["Electromagnetic Fields", "Control Systems", "Communication Networks", "Microprocessors"] },
        { semester: "4", subjects: ["Electronic Circuits", "Digital Communication", "Circuit Analysis", "VLSI Design"] },
        { semester: "5", subjects: ["Basic Electrical Engineering", "Mathematics I", "Physics", "Communication Systems"] },
        { semester: "6", subjects: ["Analog Electronics", "Mathematics II", "Signals & Systems", "Digital Circuits"] },
        { semester: "7", subjects: ["Electromagnetic Fields", "Control Systems", "Communication Networks", "Microprocessors"] },
        { semester: "8", subjects: ["Electronic Circuits", "Digital Communication", "Circuit Analysis", "VLSI Design"] }
      ]
    },
    eee: {
      title: "Electrical & Electronics Engineering",
      tagline: "Powering Innovation in Electrical Systems",
      sems: [
        { semester: "1", subjects: ["Basic Electrical Engineering", "Mathematics I", "Physics", "Circuit Theory"] },
        { semester: "2", subjects: ["Electrical Machines", "Mathematics II", "Signals and Systems", "Digital Electronics"] },
        { semester: "3", subjects: ["Power Systems", "Control Systems", "Electrical Measurements", "Electromagnetic Fields"] },
        { semester: "4", subjects: ["Electrical Machines II", "Power Electronics", "Analog Electronics", "Microprocessors"] },
        { semester: "5", subjects: ["Power Generation", "Protection Systems", "Renewable Energy", "Electrical Drives"] },
        { semester: "6", subjects: ["Power System Protection", "Control Systems II", "High Voltage Engineering", "PLC and SCADA"] },
        { semester: "7", subjects: ["Power Systems II", "Electrical Machine Design", "Electrical Distribution Systems", "Automation"] },
        { semester: "8", subjects: ["Power System Optimization", "Advanced Power Electronics", "Smart Grids", "Industrial Automation"] }
      ]
    },
    civil: {
      title: "Civil Engineering",
      tagline: "Building the Foundations of Tomorrow",
      sems: [
        { semester: "1", subjects: ["Engineering Mechanics", "Mathematics I", "Physics", "Computer Programming"] },
        { semester: "2", subjects: ["Building Materials", "Surveying", "Mathematics II", "Fluid Mechanics"] },
        { semester: "3", subjects: ["Structural Analysis", "Concrete Technology", "Geotechnical Engineering", "Transportation Engineering"] },
        { semester: "4", subjects: ["Strength of Materials", "Structural Design", "Environmental Engineering", "Hydrology"] },
        { semester: "5", subjects: ["Water Resources Engineering", "Soil Mechanics", "Environmental Management", "Construction Management"] },
        { semester: "6", subjects: ["Steel Structures", "Geotechnical Engineering II", "Reinforced Concrete Structures", "Traffic Engineering"] },
        { semester: "7", subjects: ["Advanced Structural Design", "Project Management", "Construction Planning", "Advanced Hydrology"] },
        { semester: "8", subjects: ["Bridge Engineering", "Design of Foundations", "Urban Planning", "Advanced Construction Techniques"] }
      ]
    },
    mechanical: {
      title: "Mechanical Engineering",
      tagline: "Engineering the Machines that Power the World",
      sems: [
        { semester: "1", subjects: ["Engineering Mechanics", "Mathematics I", "Physics", "Engineering Drawing"] },
        { semester: "2", subjects: ["Strength of Materials", "Fluid Mechanics", "Thermodynamics", "Manufacturing Processes"] },
        { semester: "3", subjects: ["Machine Design", "Dynamics of Machines", "Mechanical Measurements", "Heat Transfer"] },
        { semester: "4", subjects: ["Fluid Mechanics II", "Machine Design II", "Engineering Materials", "Production Technology"] },
        { semester: "5", subjects: ["Refrigeration and Air Conditioning", "Internal Combustion Engines", "Thermal Engineering", "Kinematics of Machines"] },
        { semester: "6", subjects: ["CAD/CAM", "Robotics", "Vibration Analysis", "Manufacturing Systems"] },
        { semester: "7", subjects: ["Finite Element Analysis", "Advanced Thermodynamics", "Design of Mechanical Systems", "Energy Systems"] },
        { semester: "8", subjects: ["Advanced Manufacturing", "Design for Reliability", "Automobile Engineering", "Project Management"] }
      ]
    }
  };
  return departments[department];  // Return data for the specific department
}

export default DepartmentPage;
